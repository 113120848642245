import create from 'zustand';
import { IFilter } from '../core/utils/filterQuery';
import { devtools } from 'zustand/middleware';
import { DocumentNode, LazyQueryResultTuple, useLazyQuery, useQuery } from '@apollo/client';
import { GET_ALL_CHANNELS as GET_ORDER_CHANNELS } from '../orders/queries';
import { GET_ALL_CHANNELS as GET_ORDER_CONFIRMATION_CHANNELS } from '../order_confirmations/queries';
import { GET_ALL_CHANNELS as GET_INVOICE_CHANNELS } from '../invoices/queries';
import { GET_ALL_CHANNELS as GET_RFQ_CHANNELS } from '../rfqs/queries';
import { GET_ALL_CHANNELS as GET_PROPERTY_BILL_CHANNELS } from '../db_immo/queries';
import { GET_ALL_CHANNELS as GET_DELIVERY_NOTE_CHANNELS } from '../delivery_notes/queries';
import { GET_ALL_CHANNELS as GET_LIST_OF_SERVICES_CHANNELS } from '../list_of_services/queries';
import { GET_ALL_CHANNELS as GET_UNIVERSAL_CHANNELS } from '../universal/queries';
import { DocumentType } from '../generic_document/constants';
import { QueryResult } from '@apollo/client/react/types/types';
import { createContext } from 'react';

export interface IChannel {
    id: string;
    name: string;
    __typename: string;
}

interface IState {
    readonly channelId: string | null;
    readonly userFilters: Array<IFilter> | null;
}
interface IActions {
    setActiveChannel: (channelId: string | null) => void;
    setActiveUserFilters: (filters: Array<IFilter>) => void;
}
interface IQueries {
    getDocumentChannels: (documentType: DocumentType, queryParams?: any) => QueryResult;
    useNextRecord: (
        queryNextRecord: DocumentNode,
        channelId?: string,
        filters?: Array<IFilter>
    ) => LazyQueryResultTuple<any, any>;
}
type IStore = IState & IActions & IQueries;

const initialState: IState = {
    channelId: null,
    userFilters: [],
};

export const ChannelContext = createContext({ activeChannel: null, documentType: null });

const getAllChannelsByDocumentType = {
    [DocumentType.Order]: GET_ORDER_CHANNELS,
    [DocumentType.OrderConfirmation]: GET_ORDER_CONFIRMATION_CHANNELS,
    [DocumentType.Invoice]: GET_INVOICE_CHANNELS,
    [DocumentType.Rfq]: GET_RFQ_CHANNELS,
    [DocumentType.PropertyBill]: GET_PROPERTY_BILL_CHANNELS,
    [DocumentType.DeliveryNote]: GET_DELIVERY_NOTE_CHANNELS,
    [DocumentType.ListOfServices]: GET_LIST_OF_SERVICES_CHANNELS,
    [DocumentType.Universal]: GET_UNIVERSAL_CHANNELS,
};

/**
 * Store for managing the current channel context
 */
const useStore = create<IStore, [['zustand/devtools', IStore]]>(
    devtools((set, get) => ({
        ...initialState,

        setActiveChannel: (channelId) => {
            set({ channelId });
        },
        setActiveUserFilters: (filters) => {
            set({ userFilters: [...filters] });
        },
        getDocumentChannels: (documentType, queryParams = {}) => {
            return useQuery(getAllChannelsByDocumentType[documentType], {
                ...queryParams,
            });
        },
        useNextRecord: (queryNextRecord, channelId?, filters?) => {
            return useLazyQuery(queryNextRecord, {
                variables: {
                    channelId,
                    filters,
                },
                fetchPolicy: 'network-only',
            });
        },
    }))
);

export const useChannelStore = useStore;
